import { GoogleAnalytics } from '@next/third-parties/google';
import { NextPage } from 'next';
import { NextIntlClientProvider } from 'next-intl';
import type { AppProps } from 'next/app';
import { Nunito } from 'next/font/google';
import Head from 'next/head';
import { useRouter } from 'next/router';
import type { ReactElement, ReactNode } from 'react';

import '../styles.css';

const googleAnalyticsId = process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS_ID

export type NextPageWithLayout<P = {}, IP = P> = NextPage<P, IP> & {
  getLayout?: (page: ReactElement) => ReactNode
}

type AppPropsWithLayout = AppProps & {
  Component: NextPageWithLayout
}

const nunito = Nunito({
  subsets: ['latin'],
  variable: '--font-nunito',
})

export default function MyApp({ Component, pageProps }: AppPropsWithLayout) {

  const router = useRouter();
  const getLayout = Component.getLayout ?? ((page) => page)

  return (
    <main className={nunito.className}>
      <Head>
        <meta name="viewport" content="initial-scale=1, width=device-width" />
      </Head>

      <NextIntlClientProvider
        locale={router.locale}
        messages={pageProps.messages}
        timeZone='Europe/Lisbon'
      >
        {getLayout(<Component {...pageProps} />)}
        <GoogleAnalytics gaId={googleAnalyticsId} />
      </NextIntlClientProvider>

    </main>
  );
}
